import * as actionTypes from 'api/user/login/actionTypes';
import defaultStore from '../../defaultStore';
// import { actionTypes } from 'api/user/login';

export const loginReducer = (state = defaultStore.user.login, action) => {
    switch (action.type) {
        case actionTypes.START_USER_LOGIN:
            return {
                ...state,
                isAuthenticating: true
            };
        case actionTypes.FINISH_USER_LOGIN:
            return {
                ...state,
                isAuthenticating: false
            };
        case actionTypes.USER_AUTHENTICATION_SUCCEEDED:
            return {
                ...state,
                authenticationFailed: false
            };
        case actionTypes.USER_AUTHENTICATION_FAILED:
            return {
                ...state,
                authenticationFailed: true
            };
        case actionTypes.START_USER_LOGOUT:
            return {
                ...state,
                isLoggingOut: true
            };
        case actionTypes.FINISH_USER_LOGOUT:
            return {
                ...state,
                isLoggingOut: false
            };
        default:
            return state;
    }
};
