import { put, call } from 'redux-saga/effects';
import { apiCall, guardedGenerator, redirectToDifferentCulture, guardedTakeEvery } from 'infrastructure/helpers/sagasHelper';
import { authenticateUser as authenticateUserApi, logoutUser as logoutUserApi } from 'infrastructure/authentication/authenticationRemoteApi';
import { push, setUserData, clearUserData } from 'infrastructure/messaging/actions';
import { authenticationContext } from 'infrastructure/authentication/authenticationContext';
import * as actions from './actions';
import * as actionTypes from './actionTypes';

function shouldRedirectToNewCulture(userCulture) {
    return userCulture
        && window.clientData
        && window.clientData.currentLanguage !== userCulture;
}

export function* authenticateUser(action) {
    let authenticationResult;
    let isFullRedirect = false;

    try {
        authenticationResult = yield apiCall(authenticateUserApi, action.userName, action.password);
    } catch (exc) {
        if (exc.errorCode !== 400) {
            throw exc;
        } else {
            authenticationResult = { error: 'Invalid user name or password' };
        }
    }
    if (authenticationResult.error) {
        yield put(actions.userAuthenticationFailed());
    } else {
        yield put(actions.userAuthenticationSucceeded());
        yield call(authenticationContext.setToken, authenticationResult);
        const userTokenData = yield call(authenticationContext.getUserTokenData);
        yield put(setUserData({ ...userTokenData, isAuthenticated: true }));
        const successRedirect = action.successRedirect || '/';

        if (shouldRedirectToNewCulture(userTokenData.userLanguage)) {
            yield redirectToDifferentCulture(userTokenData.userLanguage, successRedirect);
            isFullRedirect = true;
        } else {
            yield put(push(successRedirect));
        }

        return isFullRedirect;
    }
}

function* loginUser(action) {
    yield put(actions.startUserLogin());
    const isFullRedirect = yield guardedGenerator(authenticateUser, action);
    if (!isFullRedirect) {
        yield put(actions.finishUserLogin());
    }
}

export function* watchLoginUser() {
    yield guardedTakeEvery(actionTypes.LOGIN_USER, loginUser);
}

function* performLogout() {
    yield apiCall(logoutUserApi);
    yield call(authenticationContext.removeToken);
    yield put(clearUserData());
}

function* logoutUser(action) {
    yield put(actions.startUserLogout());
    yield guardedGenerator(performLogout, action);
    yield put(actions.finishUserLogout());
}

export function* watchLogoutUser() {
    yield guardedTakeEvery(actionTypes.LOGOUT_USER, logoutUser);
}
