import * as actionTypes from './actionTypes';

export const fetchFileNamesInfo = (fileNames) => ({ type: actionTypes.FETCH_FILE_NAMES_INFO, fileNames });
export const startFetchingFileNamesInfo = () => ({ type: actionTypes.START_FETCHING_FILE_NAMES_INFO });
export const finishFetchingFileNamesInfo = () => ({ type: actionTypes.FINISH_FETCHING_FILE_NAMES_INFO });
export const initFileNamesInfo = (fileNamesInfo) => ({ type: actionTypes.INIT_FILE_NAMES_INFO, fileNamesInfo });

export const startFetchingMediaElements = () => ({ type: actionTypes.START_FETCHING_MEDIA_ELEMENTS });
export const fetchMediaElements = (data) => ({ type: actionTypes.FETCH_MEDIA_ELEMENTS, data });
export const finishFetchingMediaElements = () => ({ type: actionTypes.FINISH_FETCHING_MEDIA_ELEMENTS });
export const initMediaElements = (elements) => ({ type: actionTypes.INIT_MEDIA_ELEMENTS, elements });
export const loadMoreMediaElements = () => ({ type: actionTypes.LOAD_MORE_MEDIA });
export const initMediaFilters = (searchTerm) => ({ type: actionTypes.INIT_FILTERS, searchTerm });
export const toggleMediaElementSelection = (elementName, selectionMode) => ({ type: actionTypes.TOGGLE_MEDIA_ELEMENT_SELECTION, elementName, selectionMode });

export const fetchMediaData = (elementId) => ({ type: actionTypes.FETCH_MEDIA_DATA, elementId });
export const startFetchingMediaData = () => ({ type: actionTypes.START_FETCHING_MEDIA_DATA });
export const finishFetchingMediaData = () => ({ type: actionTypes.FINISH_FETCHING_MEDIA_DATA });
export const initMediaData = (elementData) => ({ type: actionTypes.INIT_MEDIA_DATA, elementData });

export const fetchMediaConfiguration = (elementType) => ({ type: actionTypes.FETCH_MEDIA_CONFIGURATION, elementType });
export const startFetchingMediaConfiguration = () => ({ type: actionTypes.START_FETCHING_MEDIA_CONFIGURATION });
export const finishFetchingMediaConfiguration = () => ({ type: actionTypes.FINISH_FETCHING_MEDIA_CONFIGURATION });
export const initMediaConfiguration = (mediaConfigurationData) => ({ type: actionTypes.INIT_MEDIA_CONFIGURATION, mediaConfigurationData });

export const saveMediaFormData = (elementId, formName, form) => ({ type: actionTypes.SAVE_MEDIA_FORM_DATA, elementId, formName, form });

export const startSavingMediaData = () => ({ type: actionTypes.START_SAVING_MEDIA_DATA });
export const finishSavingMediaData = () => ({ type: actionTypes.FINISH_SAVING_MEDIA_DATA });

export const deleteMediaElements = (fileNames, requestData) => ({ type: actionTypes.DELETE_MEDIA_ELEMENTS, fileNames, requestData });
export const startDeleteMediaElements = () => ({ type: actionTypes.START_DELETING_MEDIA_ELEMENTS });
export const finishDeleteMediaElements = () => ({ type: actionTypes.FINISH_DELETING_MEDIA_ELEMENTS });

export const fetchMediaInfos = (files) => ({ type: actionTypes.FETCH_MEDIA_INFOS, files });
export const startFetchingMediaInfos = () => ({ type: actionTypes.START_FETCHING_MEDIA_INFOS });
export const finishFetchingMediaInfos = () => ({ type: actionTypes.FINISH_FETCHING_MEDIA_INFOS });
export const initMediaInfos = (mediaInfos) => ({ type: actionTypes.INIT_MEDIA_INFOS, mediaInfos });
