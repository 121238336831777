import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { themeOptionsReducer } from './themeOptions/reducers';
import { layoutReducer, messagePlaceholderReducer } from './layout/reducers';
import { catalogReducer } from './catalog/reducers';
import { userReducer } from './user/reducers/userReducer';
import { mediaReducer } from './media/reducers';
import { cmsReducer } from './cms/reducers';
import { userManagementReducer } from './userManagement/reducers';
import { searchReducer } from './search/reducers';
import { languageManagementReducer } from './languageManagement/reducers';
import { actionReducer } from './action/reducers';
import { configurationReducer } from './configuration/reducers';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    ThemeOptions: themeOptionsReducer,
    layout: layoutReducer,
    user: userReducer,
    form: formReducer,
    catalog: catalogReducer,
    media: mediaReducer,
    messagePlaceholder: messagePlaceholderReducer,
    cms: cmsReducer,
    userManagement: userManagementReducer,
    search: searchReducer,
    languageManagement: languageManagementReducer,
    actions: actionReducer,
    configuration: configurationReducer
});

export default rootReducer;
