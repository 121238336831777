import { combineReducers } from 'redux';
import { productTreeReducer } from './productTree/reducers';
import { newElementReducer } from './newElement/reducers';
import { elementReducer, elementTypeConfigurationReducer } from './element/reducers';
import { deleteElementReducer } from './deleteElement/reducers';
import { copyElementReducer } from './copyElement/reducers';
import { changeElementTypeReducer } from './changeElementType/reducers';
import { changeElementIdReducer } from './changeElementId/reducers';
import { activationOptionsReducer } from './elementActivationOptions/reducers';

export const catalogReducer = combineReducers({
    element: elementReducer,
    elementTypeConfiguration: elementTypeConfigurationReducer,
    productTree: productTreeReducer,
    newElement: newElementReducer,
    deleteElement: deleteElementReducer,
    copyElement: copyElementReducer,
    changeElementType: changeElementTypeReducer,
    changeElementId: changeElementIdReducer,
    activationOptions: activationOptionsReducer,
});
