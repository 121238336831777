export const FETCH_MEDIA_ELEMENTS = 'FETCH_MEDIA_ELEMENTS';
export const START_FETCHING_MEDIA_ELEMENTS = 'START_FETCHING_MEDIA_ELEMENTS';
export const FINISH_FETCHING_MEDIA_ELEMENTS = 'FINISH_FETCHING_MEDIA_ELEMENTS';
export const INIT_MEDIA_ELEMENTS = 'INIT_MEDIA_ELEMENTS';
export const LOAD_MORE_MEDIA = 'LOAD_MORE_MEDIA';
export const INIT_FILTERS = 'INIT_FILTERS';
export const TOGGLE_MEDIA_ELEMENT_SELECTION = 'TOGGLE_MEDIA_ELEMENT_SELECTION';

export const FETCH_FILE_NAMES_INFO = 'FETCH_FILE_NAMES_INFO';
export const START_FETCHING_FILE_NAMES_INFO = 'START_FETCHING_FILE_NAMES_INFO';
export const FINISH_FETCHING_FILE_NAMES_INFO = 'FINISH_FETCHING_FILE_NAMES_INFO';
export const INIT_FILE_NAMES_INFO = 'INIT_FILE_NAMES_INFO';

export const FETCH_MEDIA_DATA = 'FETCH_MEDIA_DATA';
export const FETCH_MEDIA_CONFIGURATION = 'FETCH_MEDIA_CONFIGURATION';

export const START_FETCHING_MEDIA_CONFIGURATION = 'START_FETCHING_MEDIA_CONFIGURATION';
export const FINISH_FETCHING_MEDIA_CONFIGURATION = 'FINISH_FETCHING_MEDIA_CONFIGURATION';
export const INIT_MEDIA_CONFIGURATION = 'INIT_MEDIA_CONFIGURATION';

export const START_SAVING_MEDIA_DATA = 'START_SAVING_MEDIA_DATA';
export const FINISH_SAVING_MEDIA_DATA = 'FINISH_SAVING_MEDIA_DATA';

export const SAVE_MEDIA_FORM_DATA = 'SAVE_MEDIA_FORM_DATA';
export const START_FETCHING_MEDIA_DATA = 'START_FETCHING_MEDIA_DATA';
export const FINISH_FETCHING_MEDIA_DATA = 'FINISH_FETCHING_MEDIA_DATA';
export const INIT_MEDIA_DATA = 'INIT_MEDIA_DATA';

export const DELETE_MEDIA_ELEMENTS = 'DELETE_MEDIA_ELEMENTS';
export const START_DELETING_MEDIA_ELEMENTS = 'START_DELETING_MEDIA_ELEMENTS';
export const FINISH_DELETING_MEDIA_ELEMENTS = 'FINISH_DELETING_MEDIA_ELEMENTS';

export const FETCH_MEDIA_INFOS = 'FETCH_MEDIA_INFOS';
export const START_FETCHING_MEDIA_INFOS = 'START_FETCHING_MEDIA_INFOS';
export const FINISH_FETCHING_MEDIA_INFOS = 'FINISH_FETCHING_MEDIA_INFOS';
export const INIT_MEDIA_INFOS = 'INIT_MEDIA_INFOS';
